import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ServiceCommonConstants} from "../constants/service-common.constants";

@Injectable({
    providedIn: 'root'
})
export class DirectionService {
    private data = new BehaviorSubject('');
    currentData = this.data.asObservable();
    private readonly GENERAL = `${ServiceCommonConstants.BASE_QUESTIONNAIRE_URL}/api/v1/direction`;
    private readonly GENERAL_CLIENT = `${ServiceCommonConstants.BASE_QUESTIONNAIRE_URL}/api/v1/client/direction`;

    constructor(private httpClient: HttpClient) {
    }

    updateDirection(item: any) {
        this.data.next(item);
    }

    getAllDirection() {
        return this.httpClient.get(`${this.GENERAL}/all`);
    }

    getDirectionById(id: number): Observable<any> {
        return this.httpClient.get(`/crm/questionnaire/api/v1/direction/id/${id}`);
    }

    getActiveDirectionByClientId(clientId: number): Observable<any> {
        return this.httpClient.get(`${this.GENERAL_CLIENT}/clientId/${clientId}/active/direction`);
    }

    getDirectionByCode(params: { code: string }): Observable<any> {
        return this.httpClient.get(`${this.GENERAL}/by-code`, { params });
    }

    getDirectionByCodes(codes: string[]): Observable<any> {
        let params = new HttpParams();
        codes.forEach(code => {
            params = params.append('code', code);
        });
        return this.httpClient.get(`${this.GENERAL}/by-code`, {params});
    }

}
